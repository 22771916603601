import $store from '../../store/store'
import $util from '../../common/util'

import { getGoogleSheets } from '../../common/gsxrest'

const { VUE_APP_SHEETS_PROTECTION_SIGNUPS } = process.env;

const getItemStatusIcon = (status) => {
  if (status === "NF") return {
    order: 1,
    icon: 'fas fa-times',
    color: 'red',
    description: 'Não foi feito',
    ok: false,
    sigla: status
  };
  else if (status === "FA") return {
    order: 3,
    icon: 'fas fa-check',
    color: 'yellow',
    description: 'Feito com atraso',
    ok: true,
    sigla: status

  };
  else if (status === "FE") return {
    order: 4,
    icon: 'fas fa-check',
    color: 'green',
    description: 'Feito em dia',
    ok: true,
    sigla: status
  };
  else if (status === "FU") return {
    order: 2,
    icon: 'fas fa-clock',
    color: 'blue',
    description: 'Agendado',
    ok: false,
    sigla: status
  };
  else return {
    order: 2,
    icon: 'fas fa-clock',
    color: 'blue',
    description: 'Agendado',
    ok: false,
    sigla: status
  };
}

const getItensGráfico = (sheetURI) => {
  if (sheetURI === '1lqKQgH7qC9-uO4XlROWUGJantK07B-cx8_9tUPtUMt0') {
    return getGoogleSheets(sheetURI, 'Folha1!A1:AC60')
      .then(gsxdata => {
        // eslint-disable-next-line no-unused-vars
        var gsxarr = []

        //eslint-disable-next-line
        for (const [index, product] of gsxdata.entries()) {
          if (index !== 0 && index > 4) {
            let nitem = {
              descricao: product[0],
              percentual: (product[1] && !isNaN(product[1]) ? $util.round(product[1], 0) : false),
              color: product[2],
              detailSheet: (product[3] ? product[3] : false),
              msgSheet: (product[5] ? product[5] : false),
              listSheet: (product[4] ? product[4] : false),
              link: (product[6] ? product[6] : false),
              filter: (product[6] ? $util.strToBool(product[6]) : false),
              forcepercentual: (product[7] ? $util.strToBool(product[7]) : false),
            };

            if (nitem.listSheet)
              nitem.subitens = getProtecaoItens(nitem.listSheet)

            gsxarr.push(nitem);
          } else if (index !== 0 && index <= 4) {
            let nitem = {
              descricao: product[0],
              percentual: (product[1] && !isNaN(product[1]) ? $util.round(product[1], 0) : false),
              color: product[2],
              listSheet: (product[3] ? product[3] : false),
              link: (product[4] ? product[4] : false),
              filter: (product[5] ? $util.strToBool(product[5]) : false),
            };

            if (nitem.listSheet)
              nitem.subitens = getProtecaoItens(nitem.listSheet)

            gsxarr.push(nitem);
          }

        }

        // console.log(gsxarr);
        return gsxarr;
      })
      .catch(error => {
        // console.log(error);
        $store.commit('showmsg', {
          text: "Erro ao carregar2",
          type: "error"
        });
      })
  }
  else if (sheetURI) {
    return getGoogleSheets(sheetURI, 'Sheet1!A1:AC60')
      .then(gsxdata => {
        // eslint-disable-next-line no-unused-vars
        var gsxarr = []

        //eslint-disable-next-line
        for (const [index, product] of gsxdata.entries()) {
          if (index !== 0 && index > 4) {
            let nitem = {
              descricao: product[0],
              percentual: (product[1] && !isNaN(product[1]) ? $util.round(product[1], 0) : false),
              color: product[2],
              detailSheet: (product[3] ? product[3] : false),
              msgSheet: (product[5] ? product[5] : false),
              listSheet: (product[4] ? product[4] : false),
              link: (product[6] ? product[6] : false),
              filter: (product[6] ? $util.strToBool(product[6]) : false),
              forcepercentual: (product[7] ? $util.strToBool(product[7]) : false),
            };

            if (nitem.listSheet)
              nitem.subitens = getProtecaoItens(nitem.listSheet)

            gsxarr.push(nitem);
          } else if (index !== 0 && index <= 4) {
            let nitem = {
              descricao: product[0],
              percentual: (product[1] && !isNaN(product[1]) ? $util.round(product[1], 0) : false),
              color: product[2],
              listSheet: (product[3] ? product[3] : false),
              link: (product[4] ? product[4] : false),
              filter: (product[5] ? $util.strToBool(product[5]) : false),
            };

            if (nitem.listSheet)
              nitem.subitens = getProtecaoItens(nitem.listSheet)

            gsxarr.push(nitem);
          }

        }

        // console.log(gsxarr);
        return gsxarr;
      })
      .catch(error => {
        // console.log(error);
        $store.commit('showmsg', {
          text: "Erro ao carregar2",
          type: "error"
        });
      })
  }
}

const getProtecaoItens = (sheetURI) => {
  if (sheetURI && sheetURI.length > 0) {
    return getGoogleSheets(sheetURI, 'Sheet1!A1:AC50')
      .then(gsxdatasubitens => {
        // eslint-disable-next-line no-unused-vars
        var gsxarrsubitens = []

        //eslint-disable-next-line
        var lastsubindex = -1;
        //eslint-disable-next-line
        for (const [subindex, subiten] of gsxdatasubitens.entries()) {
          if (subiten[0] && subiten[0]) {
            lastsubindex++;

            if (subiten[0] !== 'descricao' && subiten.length > 6) {
              let finalsubiten = {
                descricao: subiten[0],
                homem: {
                  do: (subiten[2] ? $util.strToBool(subiten[1]) : false),
                  status: getItemStatusIcon(subiten[2]),
                },
                mulher: {
                  do: (subiten[2] ? $util.strToBool(subiten[3]) : false),
                  status: getItemStatusIcon(subiten[4]),
                },
                crianca: {
                  do: (subiten[2] ? $util.strToBool(subiten[5]) : false),
                  status: getItemStatusIcon(subiten[6]),
                },
                info: {
                  dtprevista: (subiten[7] ? subiten[7] : false),
                  dtexecutada: (subiten[8] ? subiten[8] : false),
                  local: (subiten[10] ? subiten[10] : false),
                  auxtxt: (subiten[9] ? subiten[9] : false),
                  medico: (subiten[11] ? subiten[11] : false),
                  tipo: (subiten[12] ? subiten[12] : false),
                  temreceita: (subiten[13] ? $util.strToBool(subiten[13]) : false),
                  originalIndex: lastsubindex
                },
                subitens: []
              }
              gsxarrsubitens.push(finalsubiten);
            }
            else if (subiten[0] !== 'descricao' && subiten.length >= 6) {
              let finalsubiten = {
                descricao: subiten[0],
                percentual: (subiten[1] && !isNaN(subiten[1]) ? $util.round(subiten[1], 0) : false),
                color: subiten[2],
                listSheet: (subiten[3] ? subiten[3] : false),
                link: (subiten[4] ? subiten[4] : false),
                filter: (subiten[5] ? $util.strToBool(subiten[5]) : false),
              }
              gsxarrsubitens.push(finalsubiten);
            }

          } else {
            var addsubitem = {
              tipo: (subiten[14] ? subiten[14] : false),
              qtd: (subiten[15] ? subiten[15] : false),
              nome: (subiten[16] ? subiten[16] : false),
              preco: (subiten[17] ? subiten[17] : false)
            };

            gsxarrsubitens[lastsubindex].subitens.push(addsubitem);
          }
        }
        // console.log(gsxarrsubitens);
        return gsxarrsubitens;
      })
      .catch(error => {
        // console.log(error);
        // $store.commit('showmsg', {
        //   text: "Erro ao carregar1",
        //   type: "error"
        // });
      });
  }
}

const getItens = () => {
  return getGoogleSheets(VUE_APP_SHEETS_PROTECTION_SIGNUPS, 'Sheet1!A1:AC80')
    .then(gsxdata => {
      // eslint-disable-next-line no-unused-vars
      let gsxarr = [];

      gsxdata.map((product, index) => {
        if(index !== 0) {
          gsxarr.push({
            icone: product[0],
            beneficio: product[1],
            operadora: product[2],
            plano: product[4],
            from: product[3],
            acomodacao: product[5],
            validade: product[6],
            abrangencia: product[7],
            ncarteira: product[8],
            apolice: product[9],
            placa: product[10],
            valorVenal: product[11],
            valorvenal: product[11],
            capitalsegurado: product[12],
            saldo: product[13],
            telefone: product[14],
            site: product[15],
            linkapp: product[16]
          })
        }
      })
      return gsxarr
    })
    .catch(error => {
      // console.log(error);
      $store.commit('showmsg', {
        text: "Erro ao carregar",
        type: "error"
      });
    })
}

const calculateTotal = (items) => {
  if (items && items.length > 0) {
    var total = items
      .map(item => item.percentual)
      .reduce((prev, next) => prev + (next ? next : 0));

    var ptotal = total / items.filter(item => item.percentual).length;
    return ptotal
  } else return null
}

const getItemAccess = (perfil, item) => {
  if (perfil == "H") return item.homem;
  else if (perfil == "M") return item.mulher;
  else if (perfil == "C") return item.crianca;
}

const getAlertas = (sheetURI) => {
  if (sheetURI === '1ATh7SHrdIFfbShzLs8Q96HNBcGe8alTwybxPs4V_oCQ') {
    return getGoogleSheets(sheetURI, 'Folha1!A1:AC30')
      .then(gsxdata => {
        let gsxarr = [];

        gsxdata.map((product, index) => {
          if (index !== 0) {
            gsxarr.push({
              icone: product[0],
              mensagem: product[1],
              color: product[2],
              textcolor: product[3],
              link: (product[4] ? product[4] : false),
              condicao: (product[6] ? product[6] : false),
              size: (product[5] ? product[5] : false),
            })
          }

        })

        return gsxarr
      })
      .catch(error => {
        // console.log(error);
        $store.commit('showmsg', {
          text: "Erro ao carregar",
          type: "error"
        });
      })
  }
  else if (sheetURI) {
    return getGoogleSheets(sheetURI, 'Sheet1!A1:AC30')
      .then(gsxdata => {
        let gsxarr = [];

        gsxdata.map((product, index) => {
          if (index !== 0) {
            gsxarr.push({
              icone: product[0],
              mensagem: product[1],
              color: product[2],
              textcolor: product[3],
              link: (product[4] ? product[4] : false),
              condicao: (product[6] ? product[6] : false),
              size: (product[5] ? product[5] : false),
            })
          }

        })

        return gsxarr
      })
      .catch(error => {
        // console.log(error);
        $store.commit('showmsg', {
          text: "Erro ao carregar",
          type: "error"
        });
      })
  }
}

const protecoesUtil = {
  getItensGráfico,
  getItens,
  calculateTotal,
  getAlertas,
  getItemStatusIcon,
  getProtecaoItens,
  getItemAccess
}

export {
  protecoesUtil
}
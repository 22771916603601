<template>
  <v-dialog v-model="showdialog">
    <v-card>
      <v-form
        id="formcadastro"
        ref="formcadastro"
        @submit.prevent="salvar"
        :lazy-validation="true"
      >
        <v-container>
          <v-row align="center" align-content="center" justify="center">
            <v-col cols="12" class="text-left title">
              <v-icon left>fas fa-plug</v-icon>Conectar com a sua empresa
            </v-col>
            <v-col cols="12" class="text-left subtitle-1"
              >Complete esse cadastro e tenha acesso aos produtos oferecidos
              pela sua empresa além de outras facilidades</v-col
            >
            <v-col cols="12">
              <v-text-field
                name="cpf"
                id="cpf"
                label="Seu CPF"
                placeholder
                type="text"
                v-model="fields.cpf"
                :rules="rules"
                :validate-on-blur="false"
                v-mask="$util.formmasks.cpf"
              />
            </v-col>

            <!-- {{fields.conta}}
            <v-col cols="12">
              <v-select
                :items="contas"
                v-model="fields.conta"
                label="Sua Empresa"
                :rules="rules"
              ></v-select>
            </v-col> -->

            <v-col cols="6">
              <v-btn outlined
                block
                color="grey"
                class="grey--text"
                @click="closeDialog()"
              >
                <v-icon left>fas fa-times</v-icon>agora não
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn outlined
                block
                color="primary"
                type="submit"
                form="formcadastro"
                :loading="btnLoading"
                :disabled="btnLoading"
              >
                <v-icon left>fas fa-check</v-icon>salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { callsfrest } from "../../common/sfrest";
import { mapMutations } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showdialog: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        this.$emit("update:dialog", value);
      }
    }
  },
  data() {
    return {
      fields: {
        cpf: "",
        conta: ""
      },
      contas: [],
      rules: [],
      btnLoading: false
    };
  },
  mounted() {
    this.loadContas();
  },
  methods: {
    ...mapMutations(["showmsg", "showDefaultErrorMsg", "refreshReloadableKey"]),
    closeDialog() {
      this.showdialog = false;
    },
    loadContas() {
      callsfrest({
        method: "get",
        url: "/query",
        params: {
          q: "select Id, Name from Account order by Name"
        }
      }).then(sfdata => {
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of sfdata.records.entries()) {
          // console.log(item);
          this.contas.push({
            value: item.Id,
            text: item.Name
          });
        }
      });
    },
    salvar() {
      this.rules.push(this.$util.formrules.required);

      if (!this.$refs.formcadastro.validate()) {
        setTimeout(() => {
          this.rules.splice(0, this.rules.length);
          if (this.$refs.formcadastro)
            this.$refs.formcadastro.resetValidation();
        }, 3000);
        return;
      }

      this.btnLoading = true;
      const fields = Object.assign({}, this.fields);

      //this.closeDialog();
      //return;

      callsfrest({
        method: "post",
        url: "/sobjects/Account",
        data: {
          // eslint-disable-next-line
          RecordTypeId: process.env.VUE_APP_RECORD_TYPE_PERSON,
          Name: this.$auth.user().name,
          //Email__c: "teste" + new Date().getTime() + "@teste.com",
          Email__c: this.$auth.user().email,
          // ParentId: fields.conta,
          ParentId: process.env.VUE_APP_RECORD_PARENT,
          CPF__c: fields.cpf
        }
      })
        .then(sfdata => {
          if (sfdata && sfdata.success) {
            this.afterSave();
          } else {
            this.showDefaultErrorMsg();
          }
        })
        .catch(err => {
          this.showDefaultErrorMsg();
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    afterSave() {
      this.$auth
        .externalLogin(this.$auth.user(), {
          redirectTo: false
        })
        .then(responselogin => {
          this.showmsg({ text: "Cadastro salvo", type: "success" });
        })
        .catch(err => {
          // console.log(err);
        })
        .finally(() => {
          this.closeDialog();
        });
    }
  }
};
</script>

<template>
  <v-container
    fluid
    :key="app.reloadableKey"
    :class="'fitContainer px-1 pt-1' + (inCarousel ? '' : ' pb-1')"
    :style="inCarousel ? { 'padding-bottom': '22px !important' } : {}"
  >
    <v-btn
      color="accent"
      fab
      fixed
      bottom
      right
      id="addProtecoes"
      @click="addProtecoes()"
      v-if="!app.addProtecoes"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>
    <v-row
      class="pa-2"
      align="start"
      justify="center"
      v-if="$auth.isAuthenticated()"
    >
      <template v-if="items.length > 0">
        <template v-for="(item, index) in items">
          <v-col cols="12" :key="index">
            <v-card :class="showItemRH(item) ? ' mt-4' : ''" color="tertiary">
              <v-btn
                v-if="showItemRH(item)"
                color="secondary black--text caption elevation-1"
                rounded
                absolute
                top
                right
                small
                style="top: -18px; right: 10px;"
              >
                <span>Concedido por <strong>{{ $auth.user().sf.account.nome }}</strong></span>
              </v-btn>

              <v-card-title
                class="primary px-2 py-1 text-truncate title white--text text-center elevation-2 roundedItem"
              >
                <v-icon color="white" left>fas fa-{{ item.icone }}</v-icon>
                <span>{{ item.operadora }} <span class="text-center font-weight-bold">{{ item.beneficio }}</span></span>


              </v-card-title>
              <v-card-text class="py-1" style="position: relative;">
                <v-row no-gutters>
                  <v-col cols="12" :class="'black--text body-2 text-left'">
                    <v-row dense>
                      <template v-for="(prop, pindex) in cardProps">
                        <template v-if="item[prop.name]">
                          <v-col
                            :cols="prop.col ? prop.col : '6'"
                            :key="'f' + pindex"
                            class="pt-0 pb-1"
                          >
                            <v-col grown class="grey--text pa-0 caption">
                              {{ prop.descricao }}
                            </v-col>
                            <v-col class="pa-0" v-if="prop.name == 'site'">
                              <a target="_blank" :href="item[prop.name]">{{
                                item[prop.name]
                              }}</a>
                            </v-col>
                            <v-col
                              class="pa-0"
                              v-else-if="prop.name == 'linkapp'"
                            >
                              <a target="_blank" :href="item[prop.name]"
                                >Acessar aplicativo&nbsp;{{ item.operadora }}</a
                              >
                            </v-col>
                            <v-col class="pa-0" v-else>
                              {{ item[prop.name] }}</v-col
                            >
                          </v-col>
                        </template>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </template>
      <template v-else>
        <v-col cols="12" class="body-1 text-center"
          >Você está desprotegido</v-col
        >
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { protecoesUtil } from "./protecoesUtil";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    inCarousel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      cardProps: [
        { name: "plano", descricao: "Plano" },
        { name: "apolice", descricao: "Apólice" },
        { name: "ncarteira", descricao: "Nº Carteirinha" },
        { name: "acomodacao", descricao: "Acomodação" },
        { name: "abrangencia", descricao: "Abrangência" },
        { name: "placa", descricao: "Placa" },
        { name: "valorvenal", descricao: "Valor Venal" },
        { name: "capitalsegurado", descricao: "Capitla Segurado" },
        { name: "saldo", descricao: "Saldo" },
        { name: "validade", descricao: "Validade" },
        { name: "telefone", descricao: "Telefone" },
        { name: "site", descricao: "Site", col: "12" },
        { name: "linkapp", descricao: "Aplicativo", col: "12" },
      ]
    };
  },
  mounted() {
    protecoesUtil.getItens().then(items => {
      this.items = items;
    });
  },
  computed: {
    ...mapState(["app"]),
    itemsFiltered: function() {
      var thiz = this;
      return this.items.filter(function(item) {
        return (
          thiz.showItemRH(item) ||
          (item.from == "broker" && thiz.app.addProtecoes)
        );
      });
    }
  },
  methods: {
    ...mapMutations(["toogleAddProtecoes"]),
    addProtecoes() {
      this.toogleAddProtecoes(true);
    },
    showItemRH(item) {
      return this.$auth.userIsSF() && item.from == "rh";
    }
  }
};
</script>

<style scoped>
#addProtecoes {
  margin-bottom: var(--v-heightBottom) !important;
}
</style>

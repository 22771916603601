<template>
  <v-row dense :style="{ 'padding-bottom': '58px' }">
    <v-btn
        v-if="btnAdd"
        id="addItem"
        color="primary"
        fab
        fixed
        bottom
        right
        @click="newItem()"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>

    <template v-for="(agrupador, aindex) in agrupadores">
      <template v-if="!onlyMonths || (onlyMonths && agrupador.month)">
        <v-col cols="12" :key="'agrupador_' + '_' + aindex" class="text-center" :id="agrupador.id">
          <v-card
              class="subtitle-1 accent white--text elevation-1"

          >
            {{ agrupador.descricao }}
          </v-card>
        </v-col>
        <v-list
            :two-line="true"
            color="transparent"
            class="custom"
            v-if="agrupador.subitem"
        >
          <template
              v-for="(subitem, sindex) in agrupador.subitem"
          >
            <v-list-item
                :key="'list-item' + sindex"
                @click="protecaoDialog(subitem)"
                :id="'list-item-id-' + sindex"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                    subitem.descricao
                  }}</v-list-item-title>
                <v-list-item-subtitle
                    class="caption"
                    v-if="subitem.info && subitem.info.tipo"
                >{{ subitem.info.tipo }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                    v-if="subitem.info && subitem.info.medico"
                >
                  {{ subitem.info.medico }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    v-if="subitem.info && subitem.info.local"
                >
                  {{ subitem.info.local }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="caption mr-2">
                <v-list-item-action-text v-if="subitem.info && subitem.info.dtexecutada && subitem.info.dtexecutada !== ' '">
                  <template
                      class="purple--text"
                      v-if="subitem.info && subitem.info.dtexecutada"
                  >
                          <span class="white--text">
                            Realizado em
                            <br />
                            {{ subitem.info.dtexecutada }}
                          </span>
                  </template>
                </v-list-item-action-text>

                <v-list-item-action-text v-else>
                  <template v-if="subitem.info && subitem.info.dtprevista">
                    <template v-if="!subitem.info.dtprevista"
                    ><span class="black--text"
                    >Agendado para</span
                    ></template
                    >
                    <template v-else
                    ><span class="black--text"
                    >Previsto para</span
                    ></template
                    >
                    <br />
                    <span class="black--text font-weight-bold">{{
                        subitem.info.dtprevista
                      }}</span>
                  </template>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider
                v-if="
                      agrupador.subitem.length > 0 && agrupador.subitem.length !== 1
                    "
                :key="'divider' + '_' + sindex"
            ></v-divider>
          </template>
        </v-list>
        <div v-else class="grey--text text-center">Não possui itens</div>
      </template>
    </template>
  </v-row>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { protecoesUtil } from "./protecoesUtil";
import moment from "moment";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      moment,
      agrupadores: [
        {
          descricao: "Atrasados e Futuros",
          status: { sigla: ["NF", "FU"] },
          month: false,
          crescente: true,
          id: "agrupador_atraso",
        },
        {
          descricao: "Dezembro 2019",
          status: { sigla: ["FE", "FA"] },
          data: "/12/2019",
          month: true,
          id: "agrupador_month_1",
        },
        {
          descricao: "Novembro 2019",
          status: { sigla: ["FE", "FA"] },
          data: "/11/2019",
          month: true,
          id: "agrupador_month_2",
        },
        {
          descricao: "Outubro 2019",
          status: { sigla: ["FE", "FA"] },
          data: "/10/2019",
          month: true,
          id: "agrupador_month_3",
        },
      ],
    };
  },
  methods: {
    newItem() {
      this.$util.link("new-subiten-protecoes", {
        title: "Inserir " + this.$route.params.title,
        tipo: "Próprio",
      });
    },
    protecaoDialog(subitem) {
      this.$util.link("detail-subiten-protecoes", {
        title: this.$route.params.title,
        id: this.listID,
        perfil: subitem.carteira && subitem.carteira.perfil,
        index: subitem.info.originalIndex,
        tipo: subitem.info && subitem.info.tipo,
        nome: subitem.carteira && subitem.carteira.name,
      });
    },
    filterGroups() {
      let groupLateNext = []
      let groupDec = []
      let groupNov = []
      let groupOct = []
      let oldGroups = this.agrupadores
      this.agrupadores = []

      this.subitems.forEach((item) => {
        if(item.info && item.info.dtexecutada && moment(item.info.dtexecutada).isValid()) {
          let date = moment(item.info.dtexecutada).format('YYYY-MM-DD')
          if (parseInt(moment(date).format('D')) === 12) {
            groupDec.push(item)
          }

          if (parseInt(moment(date).format('D')) === 11) {
            groupNov.push(item)
          }

          if (parseInt(moment(date).format('D')) === 10) {
            groupOct.push(item)
          }
        }else {
          groupLateNext.push(item)
        }
      })

      oldGroups.forEach((group) => {
        if(group.id === 'agrupador_atraso') {
          group.subitem = groupLateNext;
        }

        if(group.id === 'agrupador_month_1') {
          group.subitem = groupDec;
        }

        if(group.id === 'agrupador_month_2') {
          group.subitem = groupNov;
        }

        if(group.id === 'agrupador_month_3') {
          group.subitem = groupOct;
        }
      })

      this.agrupadores = oldGroups;

    },
    selectedItemFilteredAndSorted(agrupador) {
      var nsubitems = [];
      for (const [sindex, subitem] of this.subitems.entries()) {
        for (const [cindex, carteira] of this.carteiras.entries()) {
          // if (
          //   carteira.tipo &&
          //   (this.defaultCarteira == this.allItensDescription ||
          //     this.defaultCarteira == carteira.name)
          // ) {
          var myaccess = protecoesUtil.getItemAccess(
              carteira.perfil,
              subitem
          );

          nsubitems.push({
            descricao: subitem.descricao,
            info: subitem.info,
            carteira: carteira,
          });
          // }
        }
      }

      // var filtered = nsubitems.filter(function (item) {
      //   var isvalid = false;
      //
      //   //
      //   if (agrupador.status && agrupador.status.includes(item.status.sigla)) {
      //     isvalid = true;
      //   }
      //
      //   if (isvalid && agrupador.data) {
      //     if (
      //       item.info.dtexecutada &&
      //       item.info.dtexecutada.includes(agrupador.data)
      //     ) {
      //       isvalid = true;
      //     } else if (
      //       item.info.dtprevista &&
      //       item.info.dtprevista.includes(agrupador.data)
      //     ) {
      //       isvalid = true;
      //     } else isvalid = false;
      //   }
      //
      //   return isvalid;
      //   //
      // });

      const funcParseData = (obj) => {
        var val = obj.info.dtprevista
            ? obj.info.dtprevista
            : obj.info.dtexecutada;
        var val2 = this.$util.vueFormatDateFromBR(val) + "T12:00:00.000+0000";
        return new Date(val2);
      };

      // const sorted = filtered.slice().sort((a, b) => {
      //   var dta = funcParseData(a);
      //   var dtb = funcParseData(b);
      //
      //   if (dta != dtb) {
      //     return agrupador.crescente ? dta - dtb : dtb - dta;
      //   } else if (a.status.order == b.status.order) {
      //     return a.descricao.localeCompare(b.descricao);
      //   } else {
      //     return a.status.order - b.status.order;
      //   }
      // });

      return nsubitems;
    },
  },
  computed: {
    protecoesUtilComputed() {
      return protecoesUtil;
    },
  },
  props: {
    listID: {
      type: String,
      default: "",
    },
    subitems: {
      type: Array,
      required: true,
    },
    carteiras: {
      type: Array,
      required: true,
    },
    defaultCarteira: {
      type: String,
    },
    allItensDescription: {
      type: String,
    },
    btnAdd: {
      type: Boolean,
      default: true,
    },
    onlyMonths: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.filterGroups()

  }
};
</script>

<style>
.custom .v-list-item__avatar {
  margin: 0 !important;
}
.custom .v-list-item {
  padding: 0 !important;
}

#addItem {
  margin-bottom: var(--v-heightBottom) !important;
}
</style>

<template>
  <v-container
      fluid
      id="chartContainer"
      :class="fitContainer ? 'fitContainer' : ''"
  >
    <cadastroDialog :dialog.sync="cadastroDialogBool"></cadastroDialog>

    <v-row align="start" dense :class="(fitContainer ? 'fitContainer' : '') + (inCarousel ? '' : ' pb-1') + ' px-1 pt-1' " :style="inCarousel ? { 'padding-bottom': '22px !important' } : {}" v-show="!cadastroDialogBool && ready" :key="app.reloadableKey">
      <v-col cols="12" class="pa-0" id="ucanttouchthis" v-touch="swipeChart ? { left: () => swipe('left') } : {}">
        <v-row no-gutters justify="center">
          <!-- FILTROS -->
          <v-col cols="12" justify="center" v-if="showFilter">
            <v-select v-model="defaultCarteira" :items="carteiras" item-text="name" item-value="name" background-color="transparent" small-chips dense solo class="custom" append-icon="fas fa-caret-down" :style="{ float: 'right' }"/>

            <v-select v-if="graphIDReference" v-model="defaultTipo" :items="tipos" item-text="name" item-value="name" background-color="transparent" small-chips dense solo class="custom" append-icon="fas fa-caret-down" :style="{ float: 'right' }"/>
            <div :style="{ float: 'right', 'margin-top': '8px' }" class="caption grey--text">
              FILTRAR&nbsp;
            </div>
          </v-col>

          <!-- GRAFICO -->
          <v-col cols="12 col-md-4 col-xs-4 col-xl-4" v-if="graphID">
            <div id="chart" @click="_initializeChart()"></div>
          </v-col>
        </v-row>

        <!-- LISTA PERCENTUAL/SUMARIZADOR -->
        <v-col cols="12" class="pa-0" v-if="graphID">
          <v-row dense align="stretch" justify="space-around">
            <template v-for="(item, index) in items">
              <v-col v-if="!item.subitens || allSaldoCalculated" :cols="calculateColumSize" :key="index" inset :class="'tertiary roundedItem elevation-2 text-center'" :style="{ border: 'thin solid ' + (item.color ? item.color : 'white') + ' !important' }" @click="linkItem(item)">
                <span v-html="$util.breakSpace(item.descricao)" style="font-size: 11px" class="thinbr text--primary"/>
                <br/>

                <template v-if="item.listSheet && !item.forcepercentual">
                  <span v-if="item.saldo" :style="{ color: item.color }" class="body-2">
                    <template v-if="item.saldo.todo == 0">
                      <v-icon class="pb-1" :style="{ color: item.color, 'font-size': '22px' }">fas fa-medal</v-icon>
                    </template>
                    <template v-else>
                      <span class>
                        {{ item.saldo.todo }}/<span class="font-weight-bold">{{item.saldo.total }}</span>
                      </span>
                    </template>
                  </span>
                </template>

                <template v-else-if="item.percentual">
                  <span :style="{ color: item.color }" class="subtitle-1">
                    <span class="font-weight-bold">{{ item.percentual }}</span>%
                  </span>
                </template>

                <template v-else>
                  <v-icon color="black">fas fa-ellipsis-h</v-icon>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-col>

      <!-- CARROUSEL ALERTAS -->
      <v-col cols="12" class="mt-2" v-if="alertas.length > 0">
        <v-col cols="12" class="pa-0 ma-0 caption text--primary">Meus Lembretes</v-col>
        <slider :alertas="alertas"/>
      </v-col>

      <v-col cols="12">
        <protecoesListaItens v-if="subitems" :subitems="subitems" :carteiras="carteiras" :defaultCarteira="defaultCarteira" :allItensDescription="allItensDescription" :listID="listID" :btnAdd="btnAdd" :onlyMonths="onlyMonths" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { protecoesUtil } from "./protecoesUtil";
import cadastroDialog from "./../app/cadastroDialog.vue";
import protecoesListaItens from "./protecoesListaItens.vue";
import slider from "./slider";
import { Touch } from "vuetify/lib/directives";

import { mapState, mapMutations } from "vuex";

export default {
  directives: {
    Touch
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false
    },
    showChartTotalizer: {
      type: Boolean,
      default: false
    },
    graphID: {
      type: String,
      default: ""
    },
    cardsID: {
      type: String,
      default: ""
    },
    listID: {
      type: String,
      default: ""
    },
    totalColor: {
      type: String,
      default: "#53565A"
    },
    chartSize: {
      type: String,
      default: "99%"
    },
    initialChartCircleSize: {
      type: Number,
      default: 118
    },
    eachChartCircleSize: {
      type: Number,
      default: 14
    },
    inCarousel: {
      type: Boolean,
      default: false
    },
    allItensDescription: {
      type: String,
      default: "Família"
    },
    showCadastroDialog: {
      type: Boolean,
      default: false
    },
    fitContainer: {
      type: Boolean,
      default: true
    },
    graphIDReference: {
      type: String,
      default: ""
    },
    btnAdd: {
      type: Boolean,
      default: true
    },
    onlyMonths: {
      type: Boolean,
      default: false
    },
    swipeChart: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.initialize();
  },
  watch: {
    $route() {
      this.initialize();
    },
    defaultCarteira: function(newValue, oldValue) {
      var carteira = this.getCarteiraByName(newValue);

      if (carteira && carteira.tipo) {
        this.calculateSaldoSubItens(carteira);
      } else {
        this.calculateSaldoSubItens();
      }
    },
    defaultTipo: function(newValue, oldValue) {
      if (oldValue) {
        var selectedTipo = this.tipos.filter(function(tipo) {
          return tipo.name == newValue;
        })[0];

        var tlink = selectedTipo.link
            ? selectedTipo.link
            : "detail-iten-protecoes";
        this.$util.link(tlink, {
          title: selectedTipo.name,
          listID: selectedTipo.id
        });
      }
    }
  },
  data() {
    return {
      items: [],
      subitems: false,
      iconsSubtitle: [],
      alertas: [],
      initializing: false,
      ready: false,
      chartConfig: null,
      carteiras: [],
      defaultCarteira: false,
      tipos: [],
      defaultTipo: false,
      cadastroDialog: null,
      allSaldoCalculated: false
    };
  },
  computed: {
    ...mapState(["app"]),
    cadastroDialogBool: {
      get: function() {
        if (this.$auth.userIsSF()) return false;

        return this.cadastroDialog != null
            ? this.cadastroDialog
            : this.showCadastroDialog;
      },
      set: function(value) {
        this.cadastroDialog = value;
        if (value == false) this.initialize();
      }
    },
    calculateColumSize() {
      if (this.items.length <= 3) return 3;
      else return 2;
    }
  },
  methods: {
    ...mapMutations(["refreshReloadableKey"]),
    swipe(direction) {
      if (direction == "left") {
        if (this.$route.params.activeTab != 1)
          this.$util.link("protecoes", { activeTab: 1 });
      }
    },
    getCarteiraByName(name) {
      return this.carteiras.filter(function(carteira) {
        return carteira.name == name;
      })[0];
    },
    conectarEmpresa() {
      this.cadastroDialogBool = true;
    },
    is100() {
      return protecoesUtil.calculateTotal(this.items) >= 100;
    },
    initialize() {
      //console.log("initialize");
      this.chartConfig = null;
      this.ready = false;
      this.allSaldoCalculated = false;
      this.subitems = false;

      //
      this.carteiras = [];
      this.carteiras.push({
        name: this.allItensDescription,
        tipo: false,
        perfil: "*"
      });
      this.carteiras.push({
        name: "José da Silva",
        tipo: "Titular",
        perfil: "H"
      });
      this.carteiras.push({
        name: "Maria da Silva",
        tipo: "Dependente",
        perfil: "M"
      });
      this.carteiras.push({
        name: "Enzo da Silva",
        tipo: "Dependente",
        perfil: "C"
      });

      if (this.showFilter && this.defaultCarteira == false)
        this.defaultCarteira = this.carteiras[0].name;

      //
      if (this.graphIDReference) {
        this.tipos = [];
        protecoesUtil
            .getItensGráfico(this.graphIDReference)
            .then(responseGrafico => {
              for (const [index, item] of responseGrafico.entries()) {
                if (item.filter) {
                  // console.log(item)
                  this.tipos.push({
                    name: item.descricao,
                    id: item.listSheet,
                    link: item.link
                  });
                }
              }

              if (this.showFilter && this.defaultTipo == false)
                this.defaultTipo = this.$route.params.title;
            });
      }
      //

      if (!this.initializing) {
        this.initializing = true;

        const requestGrafico = protecoesUtil.getItensGráfico(this.graphID);
        const requestAlertas = protecoesUtil.getAlertas(this.cardsID);
        const requestProtecaoItens = protecoesUtil.getProtecaoItens(
            this.listID
        );

        axios
            .all([requestGrafico, requestAlertas, requestProtecaoItens])
            .then(
                axios.spread(
                    (responseGrafico, responseAlertas, responseProtecaoItens) => {
                      // console.log({
                      //   responseGrafico,
                      //   responseAlertas,
                      //   responseProtecaoItens
                      // });

                      if (responseGrafico) {
                        this.items = responseGrafico;

                        this.calculateSaldoSubItens();
                        this.initializeChart();
                      } else this.items = [];

                      this.alertas = responseAlertas ? responseAlertas : false;

                      this.subitems = responseProtecaoItens
                          ? responseProtecaoItens
                          : false;
                    }
                )
            )
            .finally(() => {
              this.initializing = false;
              this.ready = true;
            });
      }
    },
    calculateSaldoSubItens(filterByCarteira, myItems) {
      this.allSaldoCalculated = false;

      var carteiras = this.carteiras;
      if (filterByCarteira != undefined)
        carteiras = new Array(filterByCarteira);

      var items = this.items;
      if (myItems != undefined) items = myItems;

      for (const [index, item] of items.entries()) {
        if (item.subitens) {
          item.saldo = false;
          item.subitens
              .then(subitens => {
                //  debugger;
                var total = 0;
                var todo = 0;

                for (const [cindex, carteira] of carteiras.entries()) {
                  for (const [sindex, subiten] of subitens.entries()) {
                    if (carteira && carteira.tipo) {
                      var access = false;
                      if (carteira.perfil == "H") {
                        access = subiten.homem;
                      } else if (carteira.perfil == "M") {
                        access = subiten.mulher;
                      } else if (carteira.perfil == "C") {
                        access = subiten.crianca;
                      }
                      if (access) {
                        if (access.do) {
                          total += 1;
                          if (!access.status.ok) todo += 1;
                        }
                      }
                    }
                  }
                }

                item.saldo = { total, todo };
              })
              .finally(() => {
                var allSaldoCalculated = items.filter(function(item) {
                  return item.saldo == false;
                });

                if (allSaldoCalculated.length == 0) {
                  this.allSaldoCalculated = true;
                  // this.refreshReloadableKey();
                }
              });
        }
      }
    },
    linkItem(item) {
     item.subitens.then((subitems) => {
       if (subitems.length > 0) {
         subitems.forEach((subItem) => {
           if(subItem.descricao === 'Consultas') {
             this.$util.link("detail-iten-protecoes", {
               title: subItem.descricao,
               listID: subItem.listSheet,
               graphIDReference: this.graphID
             });
           }
         })
       }
       else if (item.link) {
         this.$util.linkRedirect(item.link);
       } else if (item.detailSheet) {
         this.$util.link("detail-protecoes", {
           title: item.descricao,
           graphID: item.detailSheet,
           cardsID: item.msgSheet
         });
       }
     })

    },
    iconsSubtitleSorted() {
      return this.iconsSubtitle.slice().sort((a, b) => {
        return a.order - b.order;
      });
    },
    initializeChart(c) {
      var thiz = this;
      setTimeout(() => {
        // console.log("initializeChart");
        if (!c) c = 0;
        var container = document.querySelectorAll("#chartContainer #chart");
        if (c > 8) {
          // console.log("end initializeChart");
        } else if (container.length > 0) {
          // console.log("ok initializeChart");
          container.forEach(function(elem) {
            // console.log(elem);
            thiz._initializeChart();
          });
        } else {
          c++;
          thiz.initializeChart(c);
        }
      }, 250);
    },
    _initializeChart() {
      Highcharts.chart("chart", this.config());
    },
    buildSize(lastsize, csize) {
      var size = csize ? csize : this.eachChartCircleSize;
      var ini = lastsize ? lastsize : this.initialChartCircleSize;
      var end = ini - size;
      var arr = [ini + "%", end + 1 + "%", end];
      // console.log(arr)
      return arr;
    },
    buildChartItemBackg(item, size) {
      return {
        outerRadius: size[0],
        innerRadius: size[1],
        backgroundColor: Highcharts.Color(item.color)
            .setOpacity(0.2)
            .get(),
        borderWidth: 0
      };
    },
    buildChartItem(item, size) {
      return {
        name: item.descricao,
        data: [
          {
            color: item.color,
            radius: size[0],
            innerRadius: size[1],
            y: item.percentual
          }
        ]
      };
    },
    buildTotalItem() {
      var ptotal = protecoesUtil.calculateTotal(this.items);
      var item = {
        descricao: "Protegido",
        percentual: this.$util.round(ptotal, 0),
        color: this.totalColor
      };
      var size = this.buildSize(null, 8);
      return { item, size };
    },
    config() {
      if (!this.chartConfig) this.chartConfig = this._config();
      return this.chartConfig;
    },
    _config() {
      var config = this.baseConfig();

      if (this.items.length > 0) {
        var lastSize = null;

        if (this.showChartTotalizer) {
          var total = this.buildTotalItem();

          config.pane.background.push(
              this.buildChartItemBackg(total.item, total.size)
          );

          var totalChartItem = this.buildChartItem(total.item, total.size);
          totalChartItem.selected = true;
          config.series.push(totalChartItem);

          var lastSize = total.size[2];
        }

        for (const [index, item] of this.items.entries()) {
          if (item.percentual) {
            var size = this.buildSize(lastSize);
            lastSize = size[2];

            config.pane.background.push(this.buildChartItemBackg(item, size));
            config.series.push(this.buildChartItem(item, size));
          }
        }
      }

      // console.log(config);
      return config;
    },
    baseConfig() {
      var _ptotal = protecoesUtil.calculateTotal(this.items);
      var ptotal = this.$util.round(_ptotal, 0);

      return {
        chart: {
          type: "solidgauge",
          height: this.chartSize,
          backgroundColor: "transparent"
        },
        title: {
          text: ""
        },
        subtitle: {
          text:
              "<span style='font-size: 24px;color: " +
              this.totalColor +
              ";'><span style='font-weight: bold'>" +
              ptotal +
              "</span>%<br />Protegido</span>",
          useHTML: true,
          floating: true,
          verticalAlign: "middle"
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false
            },
            linecap: "round",
            stickyTracking: false,
            rounded: true
          },
          series: {
            events: {
              legendItemClick: function() {
                return false;
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: []
        },
        series: [],
        tooltip: { enabled: false },
        credits: {
          enabled: false
        }
      };
    }
  },
  components: {
    cadastroDialog,
    slider,
    protecoesListaItens
  }
};
</script>

<style>
.highcharts-subtitle {
  text-align: center;
}

/* select */
.custom.v-text-field > .v-input__control > .v-input__slot:before,
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.custom.v-select,
.custom.v-select div {
  width: auto !important;
}
.custom.v-select .v-text-field__details,
.custom.v-select .v-select__slot .v-select__selections input {
  display: none !important;
}
.custom.v-select .v-select__slot .v-select__selections .v-chip--select {
  padding-right: 50px !important;
}
.custom.v-select .v-select__slot .v-input__append-inner {
  margin-left: -40px !important;
  z-index: 1 !important;
}
.custom.v-select .v-input__slot {
  box-sizing: unset !important;
  -webkit-box-shadow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}
</style>
